import CloseButton from "./CloseButton";
import { useActivePhone } from "./useActivePlayer";
import styles from "./Settings.module.css";
import { useHighContrast } from "./useHighContrast";
import { useHighlightCorners } from "./useHighlightCorners";

function Settings() {
  const [phone, setPhone] = useActivePhone();
  const [highContrast, setHighContrast] = useHighContrast();
  const [highlightCorners, setHighlightCorners] = useHighlightCorners();
  return (
    <section className="container rows">
      <header className="header">
        <h1>Settings</h1>
        <CloseButton />
      </header>
      <div className={styles.settingRow}>
        <span>Current Device: {phone || "(not set)"}</span>
        <button
          className="button compact"
          onClick={() => setPhone(undefined)}
          disabled={phone === undefined}
        >
          Clear
        </button>
      </div>
      <div className={styles.settingRow}>
        <span>High contrast mode:</span>
        <input
          type="checkbox"
          checked={highContrast}
          onChange={(evt) => setHighContrast(evt.target.checked)}
        />
      </div>
      <div className={styles.settingRow}>
        <span>Highlight available corners:</span>
        <input
          type="checkbox"
          checked={highlightCorners}
          onChange={(evt) => setHighlightCorners(evt.target.checked)}
        />
      </div>
    </section>
  );
}

export default Settings;
