import {
  AppliedPiece,
  applyPiece,
  applyPieces,
  Color,
  GameState,
  getAvailableCorners,
  getMostRecentPieceIndices,
  getPlayerQuadrants,
  Piece,
  Point,
} from "./state";
import styles from "./GameBoard.module.css";
import playerClass from "./playerClass";
import { useHighContrast } from "./useHighContrast";
import { useHighlightCorners } from "./useHighlightCorners";
import { useEffect, useState } from "react";
import { useActivePlayer } from "./useActivePlayer";

function Symbol({ piece }: { piece: AppliedPiece }) {
  switch (piece.color) {
    case Color.BLUE:
      return (
        <>
          {piece.points.map(({ x, y }, i) => (
            <circle
              key={`piece-high-contrast-${piece.color}-${piece.shape}-${i}`}
              className={styles.symbol}
              cx={x + 0.5}
              cy={y + 0.5}
              r={0.3}
            />
          ))}
        </>
      );
    case Color.GREEN:
      return (
        <>
          {piece.points.map(({ x, y }, i) => (
            <rect
              key={`piece-high-contrast-${piece.color}-${piece.shape}-${i}`}
              className={styles.symbol}
              x={x + 0.25}
              y={y + 0.25}
              width={0.5}
              height={0.5}
            />
          ))}
        </>
      );
    case Color.RED:
      return (
        <>
          {piece.points.map(({ x, y }, i) => (
            <path
              key={`piece-high-contrast-${piece.color}-${piece.shape}-${i}`}
              className={styles.symbol}
              d={[
                `M${x + 0.5},${y + 0.18}`,
                `L${x + 0.82},${y + 0.5}`,
                `L${x + 0.5},${y + 0.82}`,
                `L${x + 0.18},${y + 0.5}`,
                "Z",
              ].join(" ")}
            />
          ))}
        </>
      );
    case Color.YELLOW:
      return (
        <>
          {piece.points.map(({ x, y }, i) => (
            <path
              key={`piece-high-contrast-${piece.color}-${piece.shape}-${i}`}
              className={styles.symbol}
              d={[
                `M${x + 0.5},${y + 0.2}`,
                `L${x + 0.8},${y + 0.8}`,
                `L${x + 0.2},${y + 0.8}`,
                "Z",
              ].join(" ")}
            />
          ))}
        </>
      );
  }
}

function GameBoard({
  state,
  currentPiece,
  onClick,
  animated = false,
  onAnimationComplete,
}: {
  state: GameState;
  currentPiece?: Piece | null;
  onClick?: (point: Point) => void;
  animated?: boolean;
  onAnimationComplete?: () => void;
}) {
  const pieces = applyPieces(state.board);
  const piece = currentPiece && applyPiece(currentPiece);

  const [topLeftPlayer, topRightPlayer, bottomLeftPlayer, bottomRightPlayer] =
    getPlayerQuadrants(state.board).map(
      (color) => color && { color, player: color && state.players[color] }
    );

  const OUTLINE_OFFSET = 0.2;

  const [highContrast] = useHighContrast();
  const [highlightCorners] = useHighlightCorners();

  const [activePlayerColor] = useActivePlayer(state);
  const highlightPoints: Point[] =
    highlightCorners && activePlayerColor
      ? getAvailableCorners(
          currentPiece ? [...state.board, currentPiece] : state.board,
          activePlayerColor
        )
      : [];

  const mostRecentPieceIndices = getMostRecentPieceIndices(state.board);

  const ANIMATION_PIECES_PER_SECOND = 20;
  const ANIMATION_DELAY_STEPS = 3;

  const [shownPieceIndex, setShownPieceIndex] = useState<number>(
    -ANIMATION_DELAY_STEPS
  );

  const pieceCount = state.board.length;

  useEffect(() => {
    if (animated) {
      let start: DOMHighResTimeStamp;
      let animationId = window.requestAnimationFrame(function step(
        timestamp: DOMHighResTimeStamp
      ) {
        if (start === undefined) {
          start = timestamp;
        }
        const idx =
          Math.floor(
            (timestamp - start) / (1000 / ANIMATION_PIECES_PER_SECOND)
          ) - ANIMATION_DELAY_STEPS;
        setShownPieceIndex(idx);
        if (idx < pieceCount) {
          animationId = window.requestAnimationFrame(step);
        } else {
          if (onAnimationComplete) {
            onAnimationComplete();
          }
        }
      });
      return () => window.cancelAnimationFrame(animationId);
    }
  }, [animated, pieceCount]);

  return (
    <div className={styles.table}>
      {topLeftPlayer ? (
        <span
          className={[
            styles.topLeftPlayer,
            styles.player,
            !highContrast && playerClass(topLeftPlayer.color, styles),
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {topLeftPlayer.player.name}
        </span>
      ) : null}
      {topRightPlayer ? (
        <span
          className={[
            styles.topRightPlayer,
            styles.player,
            !highContrast && playerClass(topRightPlayer.color, styles),
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {topRightPlayer.player.name}
        </span>
      ) : null}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.board}
        viewBox="-0.5 -0.5 21 21"
      >
        {[...Array(20).keys()].map((x) =>
          [...Array(20).keys()].map((y) => (
            <rect
              key={`grid-${x}:${y}`}
              x={x}
              y={y}
              width="1"
              height="1"
              strokeWidth="0.03"
              className={styles.space}
              onClick={() => onClick && onClick({ x, y })}
            />
          ))
        )}
        {pieces.map((piece, i) => (
          <g
            key={`piece-${piece.color}-${piece.shape}`}
            className={[
              styles.pieceContainer,
              animated && styles.animated,
              shownPieceIndex >= i && styles.shown,
            ]
              .filter(Boolean)
              .join(" ")}
          >
            {piece.points.map((point, j) => (
              <rect
                key={`piece-${piece.color}-${piece.shape}-${j}`}
                x={point.x}
                y={point.y}
                width="1"
                height="1"
                className={[
                  styles.piece,
                  playerClass(piece.color, styles),
                ].join(" ")}
              />
            ))}
            {mostRecentPieceIndices.get(piece.color) === i
              ? piece.points.map((point, j) => (
                  <rect
                    key={`piece-${piece.color}-${piece.shape}-${j}-recent`}
                    x={point.x}
                    y={point.y}
                    width="1"
                    height="1"
                    strokeWidth="0.07"
                    className={[
                      styles.recentPiece,
                      playerClass(piece.color, styles),
                    ].join(" ")}
                  />
                ))
              : null}
            {highContrast ? <Symbol piece={piece} /> : null}
          </g>
        ))}
        {piece && (
          <g>
            <rect
              x={Math.min(...piece.points.map(({ x }) => x)) - OUTLINE_OFFSET}
              y={Math.min(...piece.points.map(({ y }) => y)) - OUTLINE_OFFSET}
              width={
                Math.max(...piece.points.map(({ x }) => x)) -
                Math.min(...piece.points.map(({ x }) => x)) +
                1 +
                OUTLINE_OFFSET * 2
              }
              height={
                Math.max(...piece.points.map(({ y }) => y)) -
                Math.min(...piece.points.map(({ y }) => y)) +
                1 +
                OUTLINE_OFFSET * 2
              }
              strokeWidth="0.1"
              className={styles.outline}
            />
            {piece.points.map((point, i) => (
              <rect
                key={`piece-${piece.color}-${piece.shape}-${i}`}
                x={point.x}
                y={point.y}
                width="1"
                height="1"
                className={[
                  styles.piece,
                  playerClass(piece.color, styles),
                ].join(" ")}
              />
            ))}
            {highContrast ? <Symbol piece={piece} /> : null}
          </g>
        )}
        <g>
          {highlightPoints.map(({ x, y }) => (
            <circle
              key={`hint-${x}-${y}`}
              className={[
                styles.hint,
                activePlayerColor
                  ? playerClass(activePlayerColor, styles)
                  : null,
              ]
                .filter(Boolean)
                .join(" ")}
              cx={x + 0.5}
              cy={y + 0.5}
              r={0.1}
            />
          ))}
        </g>
      </svg>
      {bottomLeftPlayer ? (
        <span
          className={[
            styles.bottomLeftPlayer,
            styles.player,
            !highContrast && playerClass(bottomLeftPlayer.color, styles),
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {bottomLeftPlayer.player.name}
        </span>
      ) : null}
      {bottomRightPlayer ? (
        <span
          className={[
            styles.bottomRightPlayer,
            styles.player,
            !highContrast && playerClass(bottomRightPlayer.color, styles),
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {bottomRightPlayer.player.name}
        </span>
      ) : null}
    </div>
  );
}

export default GameBoard;
