// Types and constants

export type Point = {
  x: number;
  y: number;
};

export type ShapeName =
  | "monomino"
  | "domino"
  | "tromino-1"
  | "tromino-2"
  | "tetromino-1"
  | "tetromino-2"
  | "tetromino-3"
  | "tetromino-4"
  | "tetromino-5"
  | "pentomino-1"
  | "pentomino-2"
  | "pentomino-3"
  | "pentomino-4"
  | "pentomino-5"
  | "pentomino-6"
  | "pentomino-7"
  | "pentomino-8"
  | "pentomino-9"
  | "pentomino-10"
  | "pentomino-11"
  | "pentomino-12";

export const ALL_SHAPE_NAMES = new Set<ShapeName>([
  "monomino",
  "domino",
  "tromino-1",
  "tromino-2",
  "tetromino-1",
  "tetromino-2",
  "tetromino-3",
  "tetromino-4",
  "tetromino-5",
  "pentomino-1",
  "pentomino-2",
  "pentomino-3",
  "pentomino-4",
  "pentomino-5",
  "pentomino-6",
  "pentomino-7",
  "pentomino-8",
  "pentomino-9",
  "pentomino-10",
  "pentomino-11",
  "pentomino-12",
]);

export type Shape = Point[];

export type Rotation = 0 | 90 | 180 | 270;

export type Flip = boolean;

export enum Color {
  BLUE = "B",
  GREEN = "G",
  RED = "R",
  YELLOW = "Y",
}

export type Piece = {
  color: Color;
  shape: ShapeName;
  position: Point;
  rotation: Rotation;
  flip: Flip;
};

export type AppliedPiece = {
  color: Color;
  points: Point[];
  shape: ShapeName;
};

export type Board = Piece[];

export type Player = {
  name: string;
  phone: string;
};

export type GameState = {
  players: {
    [Color.BLUE]: Player;
    [Color.GREEN]: Player;
    [Color.RED]: Player;
    [Color.YELLOW]: Player;
  };
  currentTurn: Color | null;
  board: Board;
  forfeited: Color[];
};

export type Score = Map<Color, number>;

// Mappings

const offBoardCornerPoints = new Set([
  pointToKey({ x: -1, y: -1 }),
  pointToKey({ x: 20, y: -1 }),
  pointToKey({ x: 20, y: 20 }),
  pointToKey({ x: -1, y: 20 }),
]);

export function getShape(name: ShapeName): Shape {
  switch (name) {
    case "monomino":
      return [{ x: 0, y: 0 }];
    case "domino":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
      ];
    case "tromino-1":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
      ];
    case "tromino-2":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 0, y: 1 },
      ];
    case "tetromino-1":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
      ];
    case "tetromino-2":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 0, y: 1 },
      ];
    case "tetromino-3":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 1, y: 1 },
      ];
    case "tetromino-4":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
      ];
    case "tetromino-5":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
      ];
    case "pentomino-1":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
      ];
    case "pentomino-2":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 0, y: 1 },
      ];
    case "pentomino-3":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 1, y: 1 },
      ];
    case "pentomino-4":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 0, y: 1 },
        { x: 0, y: 2 },
      ];
    case "pentomino-5":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 1, y: 1 },
        { x: 1, y: 2 },
      ];
    case "pentomino-6":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 1, y: 1 },
        { x: 1, y: 2 },
        { x: 2, y: 2 },
      ];
    case "pentomino-7":
      return [
        { x: 0, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: 2 },
        { x: 2, y: 2 },
      ];
    case "pentomino-8":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 1, y: 2 },
      ];
    case "pentomino-9":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 2, y: 1 },
        { x: 3, y: 1 },
      ];
    case "pentomino-10":
      return [
        { x: 0, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 0, y: 2 },
        { x: 1, y: 2 },
      ];
    case "pentomino-11":
      return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 0, y: 2 },
        { x: 1, y: 2 },
      ];
    case "pentomino-12":
      return [
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 1, y: 2 },
      ];
  }
}

export function nextRotation(rotation: Rotation): Rotation {
  return ((rotation + 90) % 360) as Rotation;
}

export function nextPlayer(state: GameState): Color | null {
  if (state.currentTurn === null) {
    return null;
  }
  const order = [Color.BLUE, Color.GREEN, Color.RED, Color.YELLOW];
  const startIdx = order.indexOf(state.currentTurn) + 1;
  for (let i = 0; i < order.length; i++) {
    const idx = (i + startIdx) % order.length;
    const candidate = order[idx];
    if (
      !state.forfeited.includes(candidate) &&
      state.board.filter((piece) => piece.color === candidate).length <
        ALL_SHAPE_NAMES.size
    ) {
      return candidate;
    }
  }
  return null;
}

// Game state

function pointToKey({ x, y }: Point): string {
  return `${x}:${y}`;
}

function keyToPoint(key: string): Point {
  const [x, y] = key.split(":");
  return { x: +x, y: +y };
}

function degreesToRadians(angle: number): number {
  return (angle * Math.PI) / 180;
}

export function getCenter(piece: Piece): Point {
  const points = getShape(piece.shape);
  const xValues = points.map(({ x }) => x);
  const maxX = Math.max(...xValues);
  const minX = Math.min(...xValues);
  const yValues = points.map(({ y }) => y);
  const maxY = Math.max(...yValues);
  const minY = Math.min(...yValues);
  return {
    x: Math.round((maxX - minX) / 2 + minX),
    y: Math.round((maxY - minY) / 2 + minY),
  };
}

export function applyPiece(piece: Piece): AppliedPiece {
  return {
    shape: piece.shape,
    color: piece.color,
    points: getShape(piece.shape)
      .map((point) => {
        const center = getCenter(piece);
        if (piece.flip) {
          return {
            x: center.x + Math.round(center.x - point.x),
            y: point.y,
          };
        } else {
          return point;
        }
      })
      .map(({ x, y }) => {
        const radians = degreesToRadians(piece.rotation);
        const center = getCenter(piece);
        return {
          x: Math.round(
            Math.cos(radians) * (x - center.x) -
              Math.sin(radians) * (y - center.y) +
              center.x
          ),
          y: Math.round(
            Math.sin(radians) * (x - center.x) +
              Math.cos(radians) * (y - center.y) +
              center.y
          ),
        };
      })
      .map(({ x, y }) => ({
        x: piece.position.x + x,
        y: piece.position.y + y,
      })),
  };
}

export function applyPieces(pieces: Piece[]): AppliedPiece[] {
  return pieces.map(applyPiece);
}

function containOverlapping(points: Point[]): boolean {
  const seen = new Set<string>();
  for (const point of points) {
    const key = pointToKey(point);
    if (seen.has(key)) {
      return true;
    }
    seen.add(key);
  }
  return false;
}

function getEdgesAndCorners(piece: AppliedPiece): [Point[], Point[]] {
  const pieceKeys = new Set(piece.points.map(pointToKey));
  const edgeKeys = new Set(
    piece.points
      .reduce<Point[]>(
        (points, { x, y }) => [
          ...points,
          { x, y: y - 1 },
          { x: x + 1, y },
          { x, y: y + 1 },
          { x: x - 1, y },
        ],
        []
      )
      .map(pointToKey)
      .filter((key) => !pieceKeys.has(key))
  );
  const cornerKeys = new Set(
    piece.points
      .reduce<Point[]>(
        (points, { x, y }) => [
          ...points,
          { x: x - 1, y: y - 1 },
          { x: x + 1, y: y - 1 },
          { x: x + 1, y: y + 1 },
          { x: x - 1, y: y + 1 },
        ],
        []
      )
      .map(pointToKey)
      .filter((key) => !pieceKeys.has(key) && !edgeKeys.has(key))
  );
  return [[...edgeKeys].map(keyToPoint), [...cornerKeys].map(keyToPoint)];
}

function colorIsValid(pieces: AppliedPiece[], color: Color): boolean {
  const piecesOfColor = pieces.filter((piece) => piece.color === color);
  const pieceKeys = new Set(
    piecesOfColor
      .reduce<Point[]>((points, piece) => [...points, ...piece.points], [])
      .map(pointToKey)
  );
  return piecesOfColor.every((piece) => {
    const [edges, corners] = getEdgesAndCorners(piece);
    return (
      edges.every((edge) => !pieceKeys.has(pointToKey(edge))) &&
      corners.some((corner) => {
        const cornerKey = pointToKey(corner);
        return pieceKeys.has(cornerKey) || offBoardCornerPoints.has(cornerKey);
      })
    );
  });
}

function isOffBoard({ x, y }: Point): boolean {
  return x < 0 || x > 19 || y < 0 || y > 19;
}

export function isValid(board: Board): boolean {
  const appliedPieces = applyPieces(board);

  // Check that all pieces fall inside the board's bounds.
  if (appliedPieces.some(({ points }) => points.some(isOffBoard))) {
    return false;
  }

  // Check that no pieces overlap (within and across colors).
  if (
    containOverlapping(
      appliedPieces.reduce<Point[]>(
        (points, piece) => [...points, ...piece.points],
        []
      )
    )
  ) {
    return false;
  }

  // Check that each color adheres to the rules.
  return (
    colorIsValid(appliedPieces, Color.BLUE) &&
    colorIsValid(appliedPieces, Color.GREEN) &&
    colorIsValid(appliedPieces, Color.RED) &&
    colorIsValid(appliedPieces, Color.YELLOW)
  );
}

export function forfeit(player: Color, state: GameState): GameState {
  return {
    ...state,
    forfeited: state.forfeited.concat(player),
  };
}

export function isComplete(state: GameState): boolean {
  return state.currentTurn === null;
}

export function getPlayerQuadrants(
  board: Board
): [Color | null, Color | null, Color | null, Color | null] {
  const firstPieces = board.slice(0, 4);
  const topLeft = firstPieces.find(
    (piece) => piece.position.x < 10 && piece.position.y < 10
  );
  const topRight = firstPieces.find(
    (piece) => piece.position.x >= 10 && piece.position.y < 10
  );
  const bottomLeft = firstPieces.find(
    (piece) => piece.position.x < 10 && piece.position.y >= 10
  );
  const bottomRight = firstPieces.find(
    (piece) => piece.position.x >= 10 && piece.position.y >= 10
  );
  return [
    topLeft ? topLeft.color : null,
    topRight ? topRight.color : null,
    bottomLeft ? bottomLeft.color : null,
    bottomRight ? bottomRight.color : null,
  ];
}

export function getAvailableCorners(board: Board, player: Color): Point[] {
  const appliedPieces = applyPieces(board);
  const illegalPoints = new Set();
  for (const piece of appliedPieces) {
    for (const point of piece.points) {
      illegalPoints.add(pointToKey(point));
    }
    if (piece.color === player) {
      const [edges] = getEdgesAndCorners(piece);
      for (const edge of edges) {
        illegalPoints.add(pointToKey(edge));
      }
    }
  }

  const openCorners = new Set<string>();
  for (const piece of board) {
    if (piece.color === player) {
      const [_, corners] = getEdgesAndCorners(applyPiece(piece));
      for (const corner of corners) {
        if (!isOffBoard(corner)) {
          const key = pointToKey(corner);
          if (!illegalPoints.has(key)) openCorners.add(key);
        }
      }
    }
  }

  return [...openCorners].map(keyToPoint);
}

export function getMostRecentPieceIndices(
  board: Board
): Map<Color, number | null> {
  return board.reduce(
    (map, piece, i) => {
      map.set(piece.color, Math.max(map.get(piece.color) || 0, i));
      return map;
    },
    new Map<Color, number | null>([
      [Color.BLUE, null],
      [Color.GREEN, null],
      [Color.RED, null],
      [Color.YELLOW, null],
    ])
  );
}

function getUnplayedPieces(piecesOfColor: Piece[]): ShapeName[] {
  const unplayedPieces = new Set([...ALL_SHAPE_NAMES]);
  piecesOfColor.forEach(({ shape }) => {
    unplayedPieces.delete(shape);
  });
  return [...unplayedPieces];
}

function getPlayerScore(state: GameState, color: Color): number {
  const piecesOfColor = state.board.filter((piece) => piece.color === color);
  const unplayedPieces: ShapeName[] = getUnplayedPieces(piecesOfColor);
  if (unplayedPieces.length === 0) {
    return piecesOfColor[piecesOfColor.length - 1].shape === "monomino"
      ? 20
      : 15;
  }
  return unplayedPieces.reduce(
    (total, shapeName) => total - getShape(shapeName).length,
    0
  );
}

export function getScore(state: GameState): Score {
  return new Map([
    [Color.BLUE, getPlayerScore(state, Color.BLUE)],
    [Color.GREEN, getPlayerScore(state, Color.GREEN)],
    [Color.RED, getPlayerScore(state, Color.RED)],
    [Color.YELLOW, getPlayerScore(state, Color.YELLOW)],
  ]);
}

export function getWinners(state: GameState): Color[] {
  const score = getScore(state);
  const max = Math.max(...score.values());
  return [Color.BLUE, Color.GREEN, Color.RED, Color.YELLOW].filter(
    (color) => score.get(color) === max
  );
}
